<template>
    <div>
        <div class="auth-record-headtab">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="我的授权" name="first"></el-tab-pane>
                <el-tab-pane label="我的申请" name="second"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="request-forms" v-if="activeName == 'first'">
            <el-form :inline="true" :model="formRequestQuery" class="demo-form-inline" size="small">
                <el-form-item class="confer-type" label="授权类型">
                    <el-select v-model="formRequestQuery.type[0]" placeholder="授权类型">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="阅读授权" value="0"></el-option>
                        <el-option label="请求授权" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="confer-type" label="授权状态">
                    <el-select v-model="formRequestQuery.state[0]" placeholder="状态">
                        <el-option label="所有" value=""></el-option>
                        <el-option label="待授权" value="0"></el-option>
                        <el-option label="已通过" value="1"></el-option>
                        <el-option label="已拒绝" value="2"></el-option>
                        <el-option label="已取消" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="date" label="日期">
                    <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="formRequestQuery.start"></el-date-picker>-
                    <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="formRequestQuery.end"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="doSearch">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="request-forms" v-if="activeName == 'second'">
            <el-form :inline="true" :model="applyFormInline" class="demo-form-inline" size="small">
                <el-form-item class="confer-type" label="申请状态">
                    <el-select v-model="applyFormInline.state" placeholder="申请状态">
                        <el-option label="所有" value=""></el-option>
                        <el-option label="待授权" value="0"></el-option>
                        <el-option label="已通过" value="1"></el-option>
                        <el-option label="已拒绝" value="2"></el-option>
                        <el-option label="已取消" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="date" label="日期">
                    <el-date-picker
                    v-model="applyFormInline.start"
                    type="date"
                    size="medium"
                    value-format="yyyy-MM-dd"
                    placeholder="开始时间">
                    </el-date-picker>-
                    <el-date-picker
                    v-model="applyFormInline.end"
                    type="date"
                    size="medium"
                    value-format="yyyy-MM-dd"
                    placeholder="结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="applySearch">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        
        <RequestRecord v-if="activeName == 'first'"></RequestRecord>
        <ApplyRecord v-if="activeName == 'second'"></ApplyRecord>

    </div>
</template>
<script>
import RequestRecord from '@/views/share/RequestRecord.vue'
import ApplyRecord from  '@/views/share/ApplyRecord.vue'
export default {
    data(){
        return {
            activeName: 'first',
            formRequestQuery: {
                state:[],
                start:'',
                end:'',
                type:[]
            },
            applyFormInline: {
                state: '',
                start: '',
                end: ''
            },
            recordQuery: {
                start: '',
                end: ''
            },
        }
    },
    components: {
        RequestRecord,
        ApplyRecord
    },
    mounted() {
        if(this.$route.query.active){
            this.activeName = this.$route.query.active
        } else {
            this.activeName = 'first'
        }
    },
    methods: {
        handleClick(tab, event) {
            this.activeName = tab.name;
        },
        doSearch() {
            
           this.$ebus.$emit('doSearch', this.formRequestQuery)
        },
        applySearch(){
            this.$ebus.$emit('applySearch', this.applyFormInline)
        },
    }
}
</script>
<style lang="less" scoped>
.auth-record-headtab{
    display: flex;
    align-items: center;
    /deep/ .el-tabs.el-tabs--top {
        width: 100%;
        .el-tabs__item {
            font-size: 16px;
        }
        .is-active {
            color: #303133;
            font-weight: bold;
        }
        .el-tabs__active-bar {
            height: 3px;
            background: #303133;
        }
        .el-tabs__nav-wrap::after{
            height: 1px;
        }
        .el-tabs__header {
            margin: 0px;
        }
    }
}
.request-forms {
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-form {
        display: flex;
        align-items: center;
        .el-form-item {
            margin-bottom: 0px;
        }
        .confer-type {
            .el-select {
                width: 110px;
            }
        }
        .date {
            .el-date-editor {
                width: 150px;
            }
        }
    }
}
</style>