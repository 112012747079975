<template>
  <div>
    <el-row>
      <el-col :span="24">
        <!-- 查询 -->
        <div class="sq-box">
          <!-- <div class="serach-box">
            <div>
              <el-radio-group v-model="radio" @change="changeState(radio)" >
                状态：
                <el-radio :label="3">所有</el-radio>
                <el-radio :label="0">待授权</el-radio>
                <el-radio :label="1">已通过</el-radio>
                <el-radio :label="2">已拒绝</el-radio>
              </el-radio-group>
            </div>
            <div>时间:   
              <el-date-picker
                v-model="query.start"
                type="date"
                @change="changeData"
                value-format="yyyy-MM-dd"
                placeholder="开始时间">
              </el-date-picker> -
              <el-date-picker
              v-model="query.end"
              type="date"
              @change="changeData"
              value-format="yyyy-MM-dd"
              placeholder="结束时间">
            </el-date-picker>
            </div>
          </div> -->
          <!-- 数据 -->
          <!-- <el-table size="small" :data="list"  :show-header="false" v-model="loading">  
            <el-table-column>
              <template slot-scope="scope">
                <div class="sq-img">
                  <img src="@/assets/sq.png" alt="">
                </div>
                <div class="sq-right" >
                  <div class="sq-top">
                    <p class="sq-title"  @click="goDetail(scope.row)">{{scope.row.shareName}}</p>
                    <p class="sq-state">状态：
                      <span v-if="scope.row.state==0" >等待授权</span>
                      <span v-else-if="scope.row.state==1" style="color:green">已通过</span>
                      <span v-else style="color:red">已拒绝</span>
                    </p>
                  </div>
                  <div class="sq-time">申请时间：{{scope.row.createDate | date("yyyy年MM月dd日 HH时mm分")}}</div>
                  <div class="sq-desc">申请原因：{{scope.row.description==null?'暂无':scope.row.description}}</div>
                </div>
              </template>
            </el-table-column>
          </el-table> -->
            <el-table v-if='list.length != 0'
            :data="list"
            size="small"
            height="calc(100vh - 265px)"
            v-model="loading"
            style="width: 100%">
            <el-table-column
              label="文件名称"
              width="400">
                <template slot-scope="scope">
                    <div class="record-first">
                        <img src="@/assets/sq.png" alt="">
                        <span>{{scope.row.shareName}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
              label="状态"
              align="center">
              <template slot-scope="scope">
                  <el-tag type="primary" size="small" v-if="scope.row.state==0">待授权</el-tag>
                  <el-tag type="success" size="small" v-else-if="scope.row.state==1">已通过</el-tag>
                  <el-tag type="danger" size="small" v-else-if='scope.row.state==2'>已拒绝</el-tag>
                  <el-tag type="info" size="small" v-else>已取消</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              label="申请原因"
              align="center"
              show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.description==null?'暂无':scope.row.description}}
                </template>
            </el-table-column>
            <el-table-column
              label="申请时间"
              align="center">
                <template slot-scope="scope">
                  {{scope.row.createDate | date}}
                </template>
            </el-table-column>
            <el-table-column
            label="操作"
            align="center"
            width="150">
              <template slot-scope="scope">
                  <el-button type="text" size="small" @click="goDetail(scope.row)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
           
        </div>
      </el-col>
      <el-col :span="24">
        <div class="no-data-box" v-if='list.length == 0'>
              <img src="@/assets/no-file.svg" alt="">
              <span>暂无数据</span>
          </div>
        <div class="pagination" v-if='list.length != 0'>
          <el-pagination
            @current-change="pageChanged"
            layout="total,  prev, pager, next, jumper"
            :page-size="10"
            :total="total">
          </el-pagination>
        </div>
      </el-col>
    </el-row> 

    <ApplyrecordDialog ref="applyRecordPage" @getRequestList='getRequestList'></ApplyrecordDialog>
  </div>
</template>

<script>
import qr from 'vue-qr';
import ApplyrecordDialog from '../liankong/components/ApplyRecordDialog.vue'
export default {
  componentName: "Apply",
  components: {
    qr,
    ApplyrecordDialog
  },
  data() {
    return {
      loading:false,
      radio: 3,//默认选中所有
      query: {
        end:'',
        start:'',
        name: null,
        pageNum: 0,
        pageSize: 10,
        type:[],
        state:[]
      },
      dateTime:'',
    };
  },

computed: {
  list: function () {
    return this.$store.state.request.views.sender.list|| [];
  },
  total: function(){
    return this.$store.state.request.views.sender.paging.total || 0
  },
},
watch: {},
methods: {
  // 进入详情
  goDetail(data){
    let {createDate,disposeDate,description,resources,shareName,type,state,id,shareId} = data
    this.$mapData.put('keepMyApplyDetail',{
      createDate,
        disposeDate,
        description,
        resources,shareName,type,state,id,shareId
    })

    this.$refs.applyRecordPage.dialogVisible = true;
    this.$nextTick(()=>{
      this.$refs.applyRecordPage.onRecordInit()
    })
    // this.$router.replace({
    //   path:'/apply/myapply'
    // })
  },
  //获取数据列表
  getRequestList(reset){
    this.loading = true;
    let query = this.query;
    this.$store.state.request.views.sender.list =[];
    this.$store.dispatch("request/loadSendByMe",{
      reset:reset?true:false,
      query:query
      }).then(res => {

      this.loading = false;
    })
  },
  // 时间搜索
  changeData(val){
    this.getRequestList(true)
  },
  // 分页
  pageChanged(num){  
    this.query.pageNum=num;
    if(num==1){
      this.getRequestList(true)
    }
    this.getRequestList(false)
  },

},
created() {
  this.getRequestList(true)
},
mounted() {
  this.$ebus.$on('applySearch', recordQuery=>{
    this.query.state = [];
    this.query.start = recordQuery.start;
    this.query.end = recordQuery.end;
    if(recordQuery.state != ''){
      this.query.state.push(Number(recordQuery.state));
    }
    
    this.getRequestList(true)

  })
},
 beforeDestroy() {
    this.$ebus.$off('applySearch');
 }
};
</script>
<style lang='less' scoped>
.serach-box{
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background: #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  .el-radio-group{
    margin-top: 13px;
    .el-radio{
      margin-right: 20px;
    }
  }
}
.sq-list li{
  padding: 15px 0;
  border-bottom: 1px dashed #eee;
}
ul,li{
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
P{
  margin: 0;
  padding: 0;
}
.sq-img{
  float: left;
  width:6% ;
  img{
    width: 32px;
    height: auto;
    vertical-align: middle;
  }
}
.sq-right{
  float: right;
  width: 94%;
  .sq-top{
    display: flex;
    justify-content: space-between;
    .sq-title{
      font-size: 20px;
      color:#169BD5 ;
      margin-bottom: 15px;
      cursor: pointer;
    }
  }
}
.sq-time{
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}
.sq-desc{
  font-size: 14px;
  color: #666;
}
.pagination{
  .el-pagination {
    text-align: left;
    margin-top: 20px;
  }
}

/deep/ .el-table {
    .record-first {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
            width: 25px;
            height: 25px;
            margin-right: 5px;
        }
        span {
            display: block;
            width: 450px; 
            height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 1;
            white-space: nowrap;
            // -webkit-box-orient: vertical;
        }
        .doc-pic {
            width: 28px;
            height: 28px;
            margin-bottom: 5px;
        }
    }
    .el-table__header-wrapper {
        .has-gutter {
            tr th{
                border:none;
                background: #F2F2F2;
                color: #666;
            }
        }
    }
    .el-table__body-wrapper {
        tr td {
            border:none;
            padding: 12px 0;
        }
    }
    &::before {
        height: 0px;
    }
}
</style>